<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">Projekte</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">

        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <card no-body>
            <template slot="header">
              <div class="row">
                <div class="col-6">
                  <h3 class="mb-0">{{$t('message.orderList')}}</h3>
                </div>
                <div class="col-6 text-right">
                  <el-dropdown>
                    <base-button size="sm" type="primary" @click="showPriceList = true">
                      Filter: {{filterText}}
                    </base-button>
                    <el-dropdown-menu slot="dropdown">
                      <a class="dropdown-item" @click="changeFilter('Alle')">Alle</a>

                      <a class="dropdown-item" @click="changeFilter('Inhaltlich')">Inhaltlich</a>
                      <a class="dropdown-item" @click="changeFilter('Wirtschaftlich')">Wirtschaftlich</a>
                      <a class="dropdown-item" @click="changeFilter('Beide')">Beide</a>
                    </el-dropdown-menu>
                  </el-dropdown>

                  <!-- <base-button size="sm" type="primary" @primary-click="openExportModal"
                    :text="$t('message.export')" class="pr-1"
                    v-if="currentUserRights.includes('Angebot_Exportieren')">
                    {{ $t('message.export') }}

                  </base-button> -->

                </div>
              </div>
            </template>
            <template slot="footer">
              <div class="row">
                <div class="col-12 text-right">
                </div>
              </div>
            </template>

            <el-table ref="inputTable"
                      class="table-responsive table-flush position-table"
                      header-row-class-name="thead-light"
                      :data="items"
                      row-class-name="no-hover"
                      row-key="id"
                      max-height="750"
                      @selection-change="handleSelectionChange"
                      :row-class-name="tableRowClassName"
                      style="width: 100%">
              <el-table-column
                      sortable
                      prop="pos.key"
                      label="ID"
                      width="100">
              </el-table-column>
              <el-table-column
                      :label="$t('message.PTNumberRange')"
                      width="150">
                <template v-slot="{row}">
                  {{row.pos.pt_number}}
                </template>
              </el-table-column>
              <el-table-column
                      sortable
                      prop="pos.order_no"
                      :label="$t('global.order')"
                      width="130">
              </el-table-column>
              <el-table-column :label="$t('message.costCenter')" width="120">
                <template v-slot="{row}">
                  {{row.pos.order ? row.pos.order.Your_Reference : "-"}}
                </template>
              </el-table-column>
              <el-table-column :label="$t('message.article')" width="230" >
                <template v-slot="{row}">
                  {{row.article.No}}
                  <span v-if="row.variant">
                  / {{row.variant.Code}} {{row.variant.Description}}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="PL" width="120">
                <template v-slot="{row}">
                  {{ plUser }}
                </template>
              </el-table-column>
              <el-table-column label="ET" width="120">
                <template v-slot="{row}">
                  {{ row.ride ? row.ride.assembly : null | date }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('message.approval')" width="120" fixed="right">

              <template v-slot="{row}">
                  {{ row.pos.rel_business ? $t('message.yes') : $t('message.no') }}
                </template>
              </el-table-column>
              <el-table-column
                      :label="$t('message.ordered')"
                      width="110"
                      fixed="right">
                <template v-slot="{row}">
                  <el-checkbox v-model="row.purchased" :disabled="disabled(row.pos.rel_business)" @change="changeState(row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column
                      :label="$t('message.orderNr')"
                      width="200"
                      fixed="right">
                <template v-slot="{row}">
                  <el-input type="text" v-model="row.purchase_no"  :disabled="disabled(row.pos.rel_business)" @change="changeState(row)"></el-input>
                </template>
              </el-table-column>
              <el-table-column
                      :label="$t('message.note')"
                      width="200"
                      fixed="right">
                <template v-slot="{row}">
                  <el-input type="textarea" autosize placeholder="" :disabled="disabled(row.pos.rel_business) && !currentUserRights.includes('Einkaufsliste_Bemerkungen verfassen')" @change="changeState(row)" v-model="row.purchase_bemerkung"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </card>
        </div>
      </div>
    </div>
    <purchase-export-form
      :show.sync="exportModal"
    />
  </div>
</template>
<script>
  // Charts
  // import * as chartConfigs from '@/components/Charts/config';
  // import LineChart from '@/components/Charts/LineChart';
  // import BarChart from '@/components/Charts/BarChart';

  // // Components
  // import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  // import StatsCard from '@/components/Cards/StatsCard';

  // // Lists
  // import ActivityFeed from './ActivityFeed';
  // import TaskList from './TaskList';
  // import UserList from './UserList';
  // import ProgressTrackList from './ProgressTrackList';

  // // Tables
  // import LightTable from './LightTable';
  // import SocialTrafficTable from './SocialTrafficTable';
  // import PageVisitsTable from './PageVisitsTable';
  import { mapGetters, mapActions } from 'vuex'
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import { Sortable } from 'sortablejs'
  import api from '@/util/api.js'
  import { setTimeout } from 'timers';
  import download from '@/util/download'
  import PurchaseExportForm from './PurchaseExportForm'

  export default {
    name: 'DataInput',
    components: {
      RouteBreadCrumb,
      PurchaseExportForm
    },
    computed: {
      ...mapGetters([
        'project',
        'positions',
        'projectAddressPositions',
        'positionPerMaterial',
        'currentProjectAddress',
        'project_people',
        'currentUserRights'
      ]),
      tempPositions: {
        get() {
          return Object.assign(this.projectAddressPositions)
        },
        set (val) {
        }
      },
      totalPrice: function() {
        return this.items.reduce((val, pos) => {
                  return val + Number.parseFloat(pos.price_total)
                }, 0
        )
      },
      plUser: function() {
        const pp = this.project_people.find(p=>p.topic=='PL');
        return pp && pp.user ? pp.user.name : "-"
      }
    },
    data() {
      return {
        // project: {},
        enableSorting: true,
        items: [],
        showPriceList: false,
        sel: [],
        changed: [],
        filterText: 'Alle',
        exportModal: false
      }
    },
    watch: {
      positions (val) {
        if (val !== null) {
          setTimeout(() => {

          }, 5000)
        }
      },
      projectAddressPositions(val) {
        this.load()
      }
    },
    methods: {
      ...mapActions([
        'updatePositionSort'
      ]),
      openExportModal() {
        this.exportModal = true;
      },
      edit (row) {
        this.$router.push({ path: `/project/${this.$route.params.id}/data/${row.id}`})
      },
      addItem () {
        this.$router.push({ path: `/project/${this.$route.params.id}/data/add`})
      },
      copySelection () {

      },
      deleteSelection () {

      },
      handleSelectionChange(sel) {
        this.sel = sel
      },
      changeSelected(field, value) {
        this.sel.forEach(pos => {
          if (pos[field]!==value) {
            pos[field] = value;
            this.changed.push(pos)
          }
        })
      },
      disabled (business) {
        if (this.currentUserRights.includes('Einkaufsliste_Trotz Vorplanung bestellen')) {
          return false
        }
        return !business
      },
      saveChanged() {
        const data = {};
        this.changed.forEach(pos => data[pos.id] = pos);
        this.$store.dispatch('updatePosition', {data: data})
                .then(() => this.changed = [])
      },
      changeFilter(filter) {
        this.filterText = filter;
        this.items = this.projectAddressPositions.filter(pos => {
          if (filter=='Alle') return true;
          if (filter=='Inhaltlich') return pos.rel_content;
          if (filter=='Wirtschaftlich') return pos.rel_business;
          if (filter=='Beide') return pos.rel_content && pos.rel_business;
          return true;
        })
      },
      changeState(item) {
        const data = {
          purchased: item.purchased,
          purchase_no: item.purchase_no,
          purchase_bemerkung: item.purchase1_bemerkung,
        };
        this.$store.dispatch('updateMaterial', {id: item.id, data: data})
                .then(() => this.load())
      },
      tableRowClassName({row, rowIndex}) {
        if (row.purchased) return 'purchased';
        if (rowIndex%2==0) return 'striped';
        return '';
      },
      load() {
        this.items = this.positionPerMaterial.filter(m => m.purchase);
        this.items.forEach(m => {
          m.purchased = !!m.purchased;
        });
        this.items.sort((a, b) => {
          if (a.purchased==b.purchased) {
            return a.id - b.id
          }
          return a.purchased ? +1 : -1
        });
      }
    },
    mounted() {
      this.load();
      this.$refs.inputTable.sort('Area', 'ASC');
    }
  };
</script>
<style>
  .el-table .striped {
    background: #FAFAFA!important;
  }
  .el-table .purchased {
    background: #DDDDDD!important;
  }

</style>
